.styles_scroll-to-top__2A70v {
  border-radius: 50% !important;
  background: #fff !important;
  outline: none !important;
}

.styles_scroll-to-top__2A70v svg {
  fill: #434343 !important;
  height: 22px;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.menu-active .millsberryClz-contact-menu {
  z-index: 8 !important;
}
