.type-effect p {
  position: relative;
  text-transform: uppercase;
  font-size: 2em;
  font-weight: 900;
  letter-spacing: 4px;
  overflow: hidden;
  background: linear-gradient(90deg, #00b8de, #b02727, #00b8de);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 3s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}
