/*.feliz {*/
/*    width: 100%;*/
/*    font-size: 100px;*/
/*    font-weight: 700;*/
/*    color: #fff;*/
/*    text-align: center;*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    opacity: 0;*/
/*    animation: vem_feliz 2s ease-in-out 7s forwards;*/
/*}*/

.ano_novo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 200px 100px 0 0;
}

.ano_novo > span {
  font-size: 175px;
  font-weight: 700;
  color: #ff6e00;
}

span.seis {
  position: absolute;
  /*top: 50%;*/
  right: 52%;
  margin-right: -200px;
  animation: vai_2016 2.3s ease-in-out 2.1s forwards;
}

span.sete {
  position: absolute;
  right: 0;
  margin-right: -200px;
  animation: vem_2017 2.5s ease-in-out forwards;
}

span.sete:before {
  content: '';
  width: 0;
  height: 6px;
  display: block;
  border-radius: 3px;
  background: #ff6e00;
  transform: rotate(0deg);
  transform-origin: left top;
  position: absolute;
  top: 55px;
  left: 10px;
  z-index: -1;
  animation: entrega_balao 0.8s ease-in-out 1.4s;
}

.balao {
  width: 100px;
  height: 100px;
  display: block;
  background: #ff6e00;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  margin-top: -165px;
  right: 0%;
  margin-right: -200px;
  animation: vem_e_vai_balao 4.2s ease-in-out forwards;
}

.balao:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 20px 10px;
  border-color: transparent transparent #ff6e00 transparent;
  position: absolute;
  left: 50%;
  margin-left: -10px;
  bottom: -10px;
  z-index: -1;
}

.balao:after {
  content: '';
  width: 2px;
  height: 100px;
  display: block;
  background: #ff6e00;
  border-radius: 0 0 3px 3px;
  position: absolute;
  left: 50%;
  margin-left: -2px;
  bottom: -110px;
}

.fogos {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.fogos > div {
  border: 2px solid #ff6e00;
  position: absolute;
  opacity: 0;
  animation: solta_fogos 1.5s ease-in-out 2.8s forwards;
}

.fogos > div.f1 {
  left: 20%;
  top: 40%;
}

.fogos > div.f2 {
  left: 15%;
  top: 70%;
}

.fogos > div.f3 {
  right: 20%;
  top: 40%;
}

.fogos > div.f4 {
  right: 15%;
  top: 70%;
}

.fogos > div span {
  width: 6px;
  height: 6px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  animation: estoura_fogos 0.2s ease-in-out 2.8s forwards;
}

.fogos > div span:nth-child(1) {
  transform: rotate(0deg);
}

.fogos > div span:nth-child(2) {
  transform: rotate(120deg);
}

.fogos > div span:nth-child(3) {
  transform: rotate(240deg);
}

.fogos > div span:before {
  content: '';
  width: 2px;
  height: 50px;
  display: block;
  background: #ff6e00;
  position: absolute;
  top: -60px;
  left: 2px;
}

.fogos > div span:after {
  content: '';
  width: 2px;
  height: 50px;
  display: block;
  background: #ff6e00;
  position: absolute;
  bottom: -60px;
  left: 2px;
}

.fogos > div span i:before {
  content: '';
  width: 3px;
  height: 3px;
  display: block;
  border-radius: 50%;
  background: #ff6e00;
  position: absolute;
  top: -15px;
  left: 10px;
}

.fogos > div span i:after {
  content: '';
  width: 3px;
  height: 3px;
  display: block;
  border-radius: 50%;
  background: #ff6e00;
  position: absolute;
  top: -15px;
  right: 10px;
}
@keyframes vem_2017 {
  0% {
    right: 0%;
  }
  66.6666% {
    right: 50%;
    margin-right: -300px;
  }
  90% {
    right: 50%;
    margin-right: -300px;
  }
  100% {
    right: 50%;
  }
}

@keyframes vem_e_vai_balao {
  0% {
    right: 0%;
  }
  40% {
    right: 50%;
    margin-right: -300px;
  }
  50% {
    right: 50%;
    margin-right: -200px;
    top: 50%;
  }
  100% {
    top: -100%;
    right: 50%;
  }
}

@keyframes entrega_balao {
  0% {
    transform: rotate(-30deg);
    width: 40px;
  }
  100% {
    transform: rotate(-150deg);
    width: 70px;
  }
}

@keyframes vai_2016 {
  0% {
    top: 50%;
  }
  100% {
    top: -100%;
  }
}

/*@keyframes vem_feliz {*/
/*    0% {*/
/*        margin-top: 0;*/
/*        opacity: 0;*/
/*        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;*/
/*    }*/
/*    100% {*/
/*        margin-top: -200px;;*/
/*        opacity: 1;*/
/*        text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;*/
/*    }*/
/*}*/

@keyframes solta_fogos {
  0% {
    margin-top: 100%;
    opacity: 0;
    width: 2px;
    height: 30px;
    display: block;
    border-radius: 50%;
  }
  75% {
    margin-top: 0%;
    opacity: 1;
    width: 2px;
    height: 30px;
    display: block;
    border-radius: 50%;
  }
  80% {
    margin-top: 0px;
    margin-left: 0px;
    opacity: 1;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    transform: scale(1);
  }
}

@keyframes estoura_fogos {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
