.contact-sec .formbox .inputbox input[type='submit'] {
  padding: 8px 20px;
  margin-top: -10px;
  width: 100px;
  cursor: pointer;
  background: #ff5064;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  border: none;
  text-transform: uppercase;
  letter-spacing: 4px;
  z-index: 2;
}

.contact-sec .formbox .inputbox input[type='submit']:focus {
  background: #002147;
  color: #111111;
}

.contact-sec .formbox form .inputbox input:focus ~ span,
.contact-sec .formbox form .inputbox textarea:focus ~ span {
  transform: translateY(-24px);
  color: #002147 !important;
  font-size: 14px;
}

.contact-sec .formbox form .inputbox input:focus,
.contact-sec .formbox form .inputbox textarea:focus,
.contact-sec .formbox form .inputbox input:valid,
.contact-sec .formbox form .inputbox textarea:valid {
  border-bottom-color: #002147 !important;
  z-index: 2;
}
.MuiFormLabel-root {
  font-family: unset;
}
@media (max-width: 991px) {
  .contact-sec .formbox {
    width: 100%;
    padding: 50px 50px;
  }

  .contact-sec .formbox form {
    position: relative;
    width: 100%;
    max-width: 500px;
    padding: 0;
  }

  .contact-sec .imgBox {
    display: none;
  }
}
