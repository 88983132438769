.demo {
  padding: 30px;
  max-width: 1400px !important;
}
.demo h1 {
  font-weight: 900;
  font-size: 42px;
  color: #0088fd;
}

.demo span {
  font-size: 30px;
  font-weight: 600;
  color: #73738c;
}

.demo .content-holder {
  margin-top: 20px;
}

.demo p {
  font-size: 8px;
  font-weight: 600;
  color: #0099fd;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.demo .bg-stretch2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 200px;
  background-position: 50% 50%;
  position: relative;
  box-shadow: 10px 10px 30px #ccc;
}

.demo .bg-stretch2:hover .overlay {
  opacity: 1;
}

.demo .bg-stretch2:hover {
  box-shadow: none;
}

.demo .bg-stretch2 .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 1s ease;
  background-color: rgba(0, 0, 0, 0.5);
}

.demo .login {
  text-align: right;
}

.demo .bg-stretch2 .overlay .text-holder {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.demo .bg-stretch2 .overlay .text-holder h3 {
  font-size: 28px;
  font-weight: 700;
  text-transform: capitalize;
}

.demo .login .btn-primary {
  background: #007bff;
  border: 1px solid #007bff;
  border-radius: 30px;
  padding: 3px 8px;
  font-weight: 500;
  font-size: 12px;
}

.demo .login .btn-primary:hover {
  background: #ffffff;
  border: 1px solid #fca000;
  color: #434343;
  transition: 1s linear color;
}
