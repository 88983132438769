.news-page {
  margin: 0 0 50px;
}

.link-list {
  list-style: none;
  padding: 0;
  font-size: 14px;
}

.link-list li {
  margin: 0 0 10px;
}

.link-list li a {
  text-decoration: none;
  transition: 0.3s linear color;
  display: block;
  background: #e7e9ee;
  border-bottom: 5px solid #4285f4;
  color: #111010;
  padding: 16px 22px;
}

.link-list li a:hover {
  color: #28406e;
}

.person {
  box-shadow: 0 10px 50px #a6d1ed33;
  border-radius: 15px;
}

.person .title-hold {
  background: #28406e;
}

.person .title {
  color: #fff;
  margin: 0;
  font-size: 12px;
  line-height: 1.5;
}

.person .img-hold {
  display: flex;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  position: relative;
}

.person .img-hold:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  background: #28406ed1;
  transition: all 0.3s ease-out;
}

.person .img-hold .zoom-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  opacity: 0;
  transition: all 0.3 ease-out;
  z-index: 1;
}

.person .img-hold:hover:after {
  opacity: 1;
}
.person .img-hold:hover .zoom-icon {
  opacity: 1;
}

.person .name-txt {
  transition: 0.3s linear color;
  text-decoration: none;
  color: #28406e;
}

.person .name-txt:hover {
  color: #c60814;
}

@media (min-width: 480px) {
}
@media (min-width: 768px) {
  .link-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 10px;
    font-size: 16px;
  }

  .link-list li {
    padding: 0 10px;
    width: 33.33%;
    margin: 0 0 15px;
  }

  .news-page .news-holder {
    padding: 0 15px 15px;
  }

  .news-page .news-holder .nav-tabs {
    padding: 15px;
    font-size: 16px;
  }

  .news-page .news-holder .nav-tabs .nav-item {
    padding: 0 8px;
  }

  .news-page .news-holder .nav-tabs .nav-link {
    padding: 10px 20px;
  }

  .news-page .news-holder .tab-content {
    font-size: 15px;
    padding: 20px 30px;
  }
}
